/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import { graphql } from 'gatsby'

// Libraries
import { Nav, Accordion, useAccordionToggle } from 'react-bootstrap'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Content from 'components/Content'
import Hero from 'components/Hero';

// Styles
import 'styles/FAQTemplate.scss'

// Icons
import chevronUp from 'img/chevron-circle-up.svg'
import chevronDown from 'img/chevron-circle-down.svg'

export const query = graphql`
  query FAQPageByID($wordpress_id: Int!) {
    page: wordpressPage(
      wordpress_id: { eq: $wordpress_id }
    ) {
      path
      currentLocale: wpml_current_locale
      acf {
        heroTitle: hero_title
        heroDescription: hero_description
        heroImage: hero_image {
          localFile {
            childImageSharp {
              fluid(quality: 99, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        
        faqTitle: faq_title
        faqItems: faq_items {
          question
          answer
        }
        
        contentDescription: content_description
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

const FAQ = ({ data: { page } }) => {
  const [panelIndex, setPanelIndex] = useState()

  const CustomToggle = ({ children, eventKey }) => {
    const customOnClick = useAccordionToggle(eventKey, () => {
      setPanelIndex(eventKey === panelIndex ? null : eventKey)
    })

    const customClass = eventKey === panelIndex ? 'open' : 'closed'

    return (
      <Nav.Link className={`p-0 ${customClass}`} onClick={customOnClick}>
        {children}
      </Nav.Link>
    )
  }

  return (
    <Layout>
      <SEO yoast={{ meta: page.yoastMeta }} lang={page.currentLocale.slice(0, 2)} />
      <div className="faq-page">
        <Hero
          className="faq-hero"
          fluidImage={page.acf.heroImage.localFile.childImageSharp.fluid}
          title={page.acf.heroTitle}
          description={<Content className="mb-4" content={page.acf.heroDescription} />}
        />

        <section className="background container pb-5">
          <div className="faq-page-items background__inner">
            <h2>
              {page.acf.faqTitle}
            </h2>
            <Accordion>
              {page.acf.faqItems.map(({ question, answer }, index) => (
                <div className="mt-3" key={index}>
                  <div className="py-2">
                    <CustomToggle eventKey={index}>
                      <div className="d-flex justify-content-between w-100 text-left">
                        <div className="font-weight-bold">{question}</div>
                        <div>
                          <img
                            className="faq-chevron faq-chevron-up"
                            src={chevronUp}
                            alt=""
                          />
                          <img
                            className="faq-chevron faq-chevron-down"
                            src={chevronDown}
                            alt=""
                          />
                        </div>
                      </div>
                    </CustomToggle>
                  </div>
                  <Accordion.Collapse eventKey={index}>
                    <div
                      className="pb-3"
                      dangerouslySetInnerHTML={{ __html: answer }}
                    />
                  </Accordion.Collapse>
                </div>
              ))}
            </Accordion>
          </div>
        </section>

        <section className="background container mt-5 pb-5">
          <div dangerouslySetInnerHTML={{ __html: page.acf.contentDescription }} />
        </section>
      </div>
    </Layout>
  )
}

export default FAQ
